import React from "react"

const IndexPage = () => {
  React.useEffect(() => {
    if(window){
     window.location.replace('https://https-campusppa-com-br.rds.land/atriomed');
    }
  }, [...(typeof window !== 'undefined' ? [window]:[])])
  return <div></div>
};

export default IndexPage
